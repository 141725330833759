<div mat-dialog-content class="overflow-hidden" mat-dialog-close>
    <svg
        id="eX1PNHr0g8e1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 658.38 675.26"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g>
            <path
                d="M329.19,24.6c-63.22,0-114.66,51.44-114.66,114.66v115.05h229.32v-115.05c0-63.22-51.44-114.66-114.66-114.66Z"
                fill="#4898ce"
            />
            <path
                d="M329.19,642.37c63.22,0,114.66-51.44,114.66-114.66v-115.05h-229.32v115.05c0,63.22,51.44,114.66,114.66,114.66Z"
                fill="#7bbe75"
            />
            <path
                d="M244.86,27.02c-36.06,21.74-57.92,61-57.92,103.11v156.98h284.5v-142.61c0-105.48-115.4-184.51-226.58-117.48ZM437.08,252.75h-215.78v-122.28c0-27.76,13.14-54.2,36-69.95c86.67-59.71,179.78,1.47,179.78,83.98v108.25Z"
                fill="#2a2b5a"
                stroke="#2a2b5a"
                stroke-width="4.51"
                stroke-miterlimit="10"
            />
            <path
                d="M186.94,530.67c0,105.48,115.4,184.51,226.58,117.48c36.06-21.74,57.92-61,57.92-103.11v-156.98h-284.5v142.61ZM221.3,422.42h215.78v122.28c0,27.76-13.14,54.2-36,69.95-86.67,59.71-179.78-1.47-179.78-83.98v-108.25Z"
                fill="#2a2b5a"
                stroke="#2a2b5a"
                stroke-width="4.51"
                stroke-miterlimit="10"
            />
            <g id="eX1PNHr0g8e7_to" transform="translate(77.290007,400.239998)">
                <path
                    d="M122.06,585.42L94.47,613.01v-468.37c0-9.49-7.69-17.18-17.18-17.18s-17.18,7.69-17.18,17.18v468.37L32.53,585.43c-6.93-6.93-18.16-6.93-25.09,0s-6.93,18.16,0,25.08l57.31,57.31c3.46,3.46,8,5.2,12.54,5.2s9.08-1.73,12.54-5.2l57.31-57.31c6.93-6.93,6.93-18.16,0-25.08-6.93-6.93-18.16-6.93-25.09,0Z"
                    transform="translate(-77.290007,-400.239998)"
                    fill="#2a2b5a"
                    stroke="#2a2b5a"
                    stroke-width="4.51"
                    stroke-miterlimit="10"
                />
            </g>
            <g id="eX1PNHr0g8e8_to" transform="translate(581.085052,277.895006)">
                <path
                    d="M593.62,10.37c-3.33-3.33-7.84-5.2-12.54-5.2s-9.22,1.87-12.54,5.2L511.23,67.68c-6.93,6.93-6.93,18.16,0,25.08c6.93,6.93,18.16,6.93,25.09,0l27.59-27.59v468.27c0,9.49,7.69,17.18,17.18,17.18s17.18-7.69,17.18-17.18v-468.26l27.59,27.59c3.46,3.46,8,5.2,12.54,5.2s9.08-1.73,12.54-5.2c6.93-6.93,6.93-18.16,0-25.08L593.63,10.38Z"
                    transform="translate(-581.085052,-277.895006)"
                    fill="#2a2b5a"
                    stroke="#2a2b5a"
                    stroke-width="4.51"
                    stroke-miterlimit="10"
                />
            </g>
        </g>
        <g id="eX1PNHr0g8e9_to" transform="translate(75.047488,-941.535784)">
            <path
                d="M122.06,585.42L94.47,613.01v-468.37c0-9.49-7.69-17.18-17.18-17.18s-17.18,7.69-17.18,17.18v468.37L32.53,585.43c-6.93-6.93-18.16-6.93-25.09,0s-6.93,18.16,0,25.08l57.31,57.31c3.46,3.46,8,5.2,12.54,5.2s9.08-1.73,12.54-5.2l57.31-57.31c6.93-6.93,6.93-18.16,0-25.08-6.93-6.93-18.16-6.93-25.09,0Z"
                transform="translate(-75.047486,258.709952)"
                fill="#2a2b5a"
                stroke="#2a2b5a"
                stroke-width="4.51"
                stroke-miterlimit="10"
            />
        </g>
        <g id="eX1PNHr0g8e10_to" transform="translate(581.085045,965.215468)">
            <path
                d="M593.62,10.37c-3.33-3.33-7.84-5.2-12.54-5.2s-9.22,1.87-12.54,5.2L511.23,67.68c-6.93,6.93-6.93,18.16,0,25.08c6.93,6.93,18.16,6.93,25.09,0l27.59-27.59v468.27c0,9.49,7.69,17.18,17.18,17.18s17.18-7.69,17.18-17.18v-468.26l27.59,27.59c3.46,3.46,8,5.2,12.54,5.2s9.08-1.73,12.54-5.2c6.93-6.93,6.93-18.16,0-25.08L593.63,10.38Z"
                transform="translate(-581.085052,-277.895006)"
                fill="#2a2b5a"
                stroke="#2a2b5a"
                stroke-width="4.51"
                stroke-miterlimit="10"
            />
        </g>
    </svg>
</div>
